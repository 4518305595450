import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

// import i18n from "i18next";
import {
  FooterSection,
  // Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  // Empty,
  // FooterContainer,
  Language,
  LanguageSwitchContainer,
  LanguageSwitch,
  Label,
  // Label,
  // LanguageSwitch,
  // LanguageSwitchContainer,
} from "./styles";
import i18n from "../../translation";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection id={"Footer"}>
        <Container>
          <Row justify="start">


            <Col lg={8} md={8} sm={12} xs={12}>
              <Language>{t("Address")}</Language>
              <Para>{t('Riyadh')}</Para>
              <Para>{t('Saudi Arabia')}</Para>

            </Col>


            <Col lg={6} md={6} sm={12} xs={12}>
              <Label htmlFor="select-lang">{t("Language")}</Label>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange("en")}>
                  <SvgIcon
                    src="united-states.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("ar")}>
                  <SvgIcon
                    src="saudi.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="logo.svg"
                  aria-label="homepage"
                  width="101px"
                  height="64px"
                />
              </LogoContainer>
            </NavLink>


            <SocialLink
              href="https://twitter.com/tatweriah"
              src="twitter.svg"
            />
            <SocialLink
              href="mailto:info@tatweriah.sa"
              src="email.svg"
            />
            <SocialLink
              href="https://wa.me/966598565585 "
              src="whatsapp.svg"
            />



          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
